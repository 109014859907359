/*
Theme Color
By Default
Sidebar
Wrapper
Responsive Show / Hide
Theme Text
Theme Background
Theme Border
Theme Button
Scrollbar
Responsive Navbar Routes
Typewriter Effect
As Per Requirements
*/

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

/* Theme Color */
:root {
  --primary-color: #8d1400;
  --primary-color-light: #fec601;
  --primary-color-dark: #5a0e00;
  --primary-bg-color: #f3e7e5;
  --theme-success: #3ea743;
  --white: #fff;
  --dark: #343a40;
  --dark2: #00000020;
  --light2: #f2f2f2;
}
/* By Default */
a {
  text-decoration: none;
}

li {
  list-style: none;
}

p {
  margin-bottom: 0;
}

.table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: fixed !important;
}
table thead tr th {
  text-wrap: nowrap;
}
table tbody tr td {
  vertical-align: middle;
}

/* Sidebar */
#sidebar {
  width: 70px;
  min-width: 70px;
  z-index: 1000;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--primary-border-color);
  height: 100vh;
  position: relative;
}

#sidebar.expand {
  width: 260px;
  min-width: 260px;
}

.toggle-btn {
  background-color: transparent;
  cursor: pointer;
  border: 0;
  padding: 1rem 1.5rem;
}

.toggle-btn i {
  font-size: 1.5rem;
  color: var(--dark);
}

.sidebar-logo {
  margin: auto 0;
}

.sidebar-logo a {
  color: var(--dark);
  font-size: 1.15rem;
  font-weight: 600;
}

.sidebar-footer {
  border-top: 1px solid var(--light2);
  position: absolute;
  bottom: 0;
  left: 0;
}

#sidebar:not(.expand) .sidebar-logo,
#sidebar:not(.expand) a.sidebar-link span,
#sidebar:not(.expand) .sidebar-footer {
  display: none;
}

.sidebar-nav {
  padding: 0;
  flex: 1 1 auto;
}

a.sidebar-link {
  padding: 0.625rem 1.625rem;
  color: var(--dark);
  display: block;
  font-size: 0.9rem;
  white-space: nowrap;
  border-left: 3px solid transparent;
  display: flex;
  align-items: center;
}

.sidebar-link i {
  font-size: 1.1rem;
  margin-right: 0.75rem;
}

a.sidebar-link:hover {
  background-color: var(--primary-bg-color);
  border-left: 3px solid var(--primary-color);
}

a.sidebar-link.active {
  background-color: var(--primary-color);
  border-left: 3px solid var(--primary-color);
  color: var(--white);
}

.sidebar-item {
  position: relative;
}

#sidebar:not(.expand) .sidebar-item .sidebar-dropdown {
  position: absolute;
  top: 0;
  left: 70px;
  background-color: #0e2238;
  padding: 0;
  min-width: 15rem;
  display: none;
}

#sidebar:not(.expand) .sidebar-item:hover .has-dropdown + .sidebar-dropdown {
  display: block;
  max-height: 15em;
  width: 100%;
  opacity: 1;
}

#sidebar.expand .sidebar-link[data-bs-toggle="collapse"]::after {
  border: solid;
  border-width: 0 0.075rem 0.075rem 0;
  content: "";
  display: inline-block;
  padding: 2px;
  position: absolute;
  right: 1.5rem;
  top: 1.4rem;
  transform: rotate(-135deg);
  transition: all 0.2s ease-out;
}

#sidebar.expand .sidebar-link[data-bs-toggle="collapse"].collapsed::after {
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}

.hide-when-collapsed {
  display: none;
}

/* Wrapper */
.wrapper {
  display: flex;
}
@media (max-width: 600px) {
  .wrapper {
    flex-direction: column;
  }
}
.wrapper-main {
  height: 100vh;
  width: 100%;
  overflow: auto;
  transition: all 0.35s ease-in-out;
}

/* Responsive Show / Hide */
.d-none-sm {
  display: block !important;
}
.d-block-sm {
  display: none !important;
}
@media (max-width: 600px) {
  .d-none-sm {
    display: none !important;
  }
  .d-block-sm {
    display: block !important;
  }
  .text-center-sm {
    text-align: center;
  }
  .justify-content-center-sm {
    justify-content: center;
  }
}
.d-none-md-768p {
  display: block !important;
}
.d-block-md-768p {
  display: none !important;
}
@media (max-width: 768px) {
  .d-none-md-768p {
    display: none !important;
  }
  .d-block-md-768p {
    display: block !important;
  }
}

.d-none-md {
  display: block !important;
}
.d-block-md {
  display: none !important;
}
@media (max-width: 992px) {
  .d-none-md {
    display: none !important;
  }
  .d-block-md {
    display: block !important;
  }
}

/* Theme Text */
.text-primary-theme {
  color: var(--primary-color) !important;
}
.text-success-theme {
  color: var(--theme-success) !important;
}
.text-primary-light-theme {
  color: var(--primary-color-light) !important;
}
.text-primary-dark-theme {
  color: var(--primary-color-dark) !important;
}

/* Theme Background */
.bg-primary-theme {
  background-color: var(--primary-color);
}
.bg-primary-light-theme {
  background-color: var(--primary-bg-color);
}

.bg-success-theme {
  background-color: var(--theme-success);
}

/* Theme Border */
.border-primary-theme {
  border-color: var(--primary-color) !important;
}
.border-primary-light-theme {
  border-color: var(--primary-color-light) !important;
}
.border-primary-dark-theme {
  border-color: var(--primary-color-dark) !important;
}

/* Theme Button */
.btn-primary-theme {
  color: var(--white);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  cursor: pointer;
  transition: all 0.4s linear;
}
.btn-primary-theme:hover {
  background-color: var(--primary-color-dark);
  color: var(--white);
}

.btn-outline-primary-theme {
  color: var(--primary-color);
  background-color: transparent;
  border-color: var(--primary-color);
  cursor: pointer;
  transition: all 0.4s linear;
}

.btn-outline-primary-theme:hover {
  background-color: var(--primary-color);
  color: var(--white);
}

.btn-outline-primary-theme:active {
  background-color: var(--primary-color-dark);
  border-color: var(--primary-color-dark);
  color: var(--white);
}

.btn-secondary-theme {
  background-color: #e9e9ff;
  color: var(--primary-color-dark);
  border-color: #e9e9ff;
  cursor: pointer;
  transition: all 0.4s linear;
}
.btn-secondary-theme:hover {
  background-color: var(--primary-color-light);
  border-color: var(--primary-color-light);
}
.btn-secondary-theme:active {
  background-color: var(--primary-color-dark);
  color: var(--white);
  border-color: var(--primary-color-dark);
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background: var(--dark2);
}
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-light);
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Responsive Navbar Routes */
@media (max-width: 600px) {
  .custom_navbar-collapse {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .custom_navbar-collapse.custom_show {
    display: flex;
    animation: custom_fadeIn 0.5s;
  }

  .custom_navbar-nav {
    flex-direction: column;
    gap: 0.5rem;
  }
}

@keyframes custom_fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Typewriter Effect */
.typewriter-text {
  overflow: hidden;
  border-right: 0.15em solid var(--primary-color);
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 4s steps(30, end) infinite,
    blink-caret 0.5s step-end infinite;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: var(--primary-color);
  }
}

/* As Per Requirements */

.h-100vh {
  height: 100vh;
}

.bg-cloud {
  /* background: url("../Assets/Images/cloud.svg"); */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}

.btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px !important;
  height: 36px !important;
  transition: background-color 0.3s ease;
}

.remove-after-icon::after {
  content: none !important;
}

.img-cover-center {
  object-fit: cover;
  object-position: center;
}
.img-contain-center {
  object-fit: contain;
  object-position: center;
}

.pointer {
  cursor: pointer;
}
.pointer-none {
  cursor: default;
}

.fs-sm {
  font-size: 12px;
}
.fs-sm-2 {
  font-size: 14px;
}

.mt-sm-3rem {
  margin-top: 0;
}
@media (max-width: 768px) {
  .mt-sm-3rem {
    margin-top: 3rem;
  }
}

.sticky-header {
  z-index: 10;
  position: sticky;
  top: 0;
}

/* //DATA LOADER------------ */
.data-loader {
  border: 4px solid rgb(244, 229, 229);
  border-left-color: transparent;
  border-radius: 50%;
}

.data-loader {
  border: 4px solid rgba(240, 235, 235, 0.971);
  border-left-color: transparent;
  width: 36px;
  height: 36px;
}

.data-loader {
  border: 4px solid rgb(248, 245, 245);
  border-left-color: transparent;
  width: 36px;
  height: 36px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pointer {
  cursor: pointer !important;
}
.text-link {
  color: #6c757d;
}
.text-link:hover {
  cursor: pointer !important;
  text-decoration: underline;
  color: var(--primary-color-dark);
}

/* Skeleton Loader */
.skeleton-loader {
  margin-bottom: 10px;
  border: 1px solid #f0f8ff;
  padding: 15px;
  background-color: #f0f8ff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.skeleton-loader:after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(
    110deg,
    rgba(227, 227, 227, 0) 0%,
    rgba(227, 227, 227, 0) 40%,
    rgba(227, 227, 227, 0.5) 50%,
    rgba(227, 227, 227, 0) 60%,
    rgba(227, 227, 227, 0) 100%
  );
  animation: gradient-animation 1.2s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
}

.skeleton-loader > div {
  background-color: rgba(185, 217, 235, 0.7);
}

.circle-skeleton {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.h1-skeleton {
  height: 24px;
  width: 100%;
}

.h2-skeleton {
  height: 20px;
  width: 100%;
}

.h3-skeleton {
  height: 18px;
  width: 100%;
}

.h4-skeleton {
  height: 16px;
  width: 100%;
}

.h5-skeleton {
  height: 14px;
  width: 100%;
}

.p-skeleton {
  height: 16px;
  width: 100%;
}

.p-skeleton.short {
  width: 92%;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Page Loader */
.dot-spinner {
  --uib-size: 5rem;
  --uib-speed: 0.9s;
  --uib-color: var(--primary-color-dark);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: "";
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Complaint */
.recent-complaint-section {
  overflow: auto;
  height: 60vh;
}

@media (min-width: 768px) {
  .recent-complaint-section {
    height: 60vh;
  }
}

@media (min-width: 1200px) {
  .recent-complaint-section {
    height: 62vh;
  }
}

/*Pagination*/
.pagination-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-link {
  color: #262a2e;
  border-color: #d8d8d8;
  min-width: 40px;
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}
.page-link:focus,
.page-link:hover {
  color: #262a2e;
  box-shadow: none;
  background-color: #eaeaea;
  border-color: #d8d8d8;
}

.pagination .page-item.active .page-link {
  background: var(--bs-body-main-color);
  border-color: var(--bs-body-main-color);
  color: #fff;
}
.pagination .page-item.disabled .page-link {
  color: #b5b5b5;
}
.pagination .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.pagination .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.pagination.custom-pagination .page-item + .page-item {
  padding-left: 0.5rem;
  margin: 0;
}
.pagination.custom-pagination .page-link {
  padding: 0.5rem 0.8rem;
  border-radius: 0.5rem;
}
.pagination.custom-pagination.pagination-rounded .page-link {
  border-radius: 50rem;
}
.pagination.custom-pagination.pagination-rounded
  .page-item:first-child
  .page-link,
.pagination.custom-pagination.pagination-rounded
  .page-item:last-child
  .page-link {
  border-radius: 50rem;
}
.pagination.custom-pagination.pagination-filled
  .page-item:not(.active)
  .page-link {
  background: #f7f7f7;
  border-color: #f7f7f7;
}
.pagination.custom-pagination.pagination-filled
  .page-item:not(.active):hover
  .page-link {
  background-color: #eaeaea;
}
.pagination.custom-pagination.pagination-simple
  .page-item:not(.active)
  .page-link {
  border-color: transparent;
}
.pagination.pagination-sm .page-link {
  padding: 0.3rem 0.6rem;
  min-height: 30px;
  min-width: 30px;
}

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.custom-dropdown-btn::after {
  display: none !important;
}

/* APICALL LOADER---- */
.api-spinner {
  --clr: #fff;
  --gap: 6px;
  /* gap between each circle */
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap);
}

.api-spinner span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: var(--clr);
  opacity: 0;
}

.api-spinner span:nth-child(1) {
  animation: fade 1s ease-in-out infinite;
}

.api-spinner span:nth-child(2) {
  animation: fade 1s ease-in-out 0.33s infinite;
}

.api-spinner span:nth-child(3) {
  animation: fade 1s ease-in-out 0.66s infinite;
}

@keyframes fade {
  0%,
  100% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }
}

.customClassForDataLoader {
  /* width: 100%; */
  display: grid;
  place-items: center;
}

/* //DATA LOADER---- */
.data-loader {
  border: 4px solid --var(--primary-color-light);
  border-left-color: transparent;
  border-radius: 50%;
}

.data-loader {
  border: 4px solid --var(--primary-color-light);
  border-left-color: transparent;
  width: 36px;
  height: 36px;
}

.data-loader {
  border: 4px solid --var(--primary-color-light);
  border-left-color: transparent;
  width: 36px;
  height: 36px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.text-link {
  color: #343a40;
  text-decoration: none;
}

.text-link:hover {
  color: var(--primary-color);
}

.text-link svg {
  opacity: 0;
  transition: opacity 0.3s;
}

.text-link:hover svg {
  opacity: 1;
}

@media (max-width: 576px) {
  .text-link {
    color: var(--primary-color);
  }
}

.flex-sm-wrap {
  flex-wrap: nowrap !important;
}

@media (max-width: 576px) {
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
}

.text-start-sm {
  text-align: right;
}
.justify-content-start-sm {
  justify-content: end;
}
@media (max-width: 768px) {
  .text-start-sm {
    text-align: left;
  }
  .justify-content-start-sm {
    justify-content: start;
  }
  .flex-col-reverse-md {
    flex-direction: column-reverse;
  }
}

/***********INVOICE **********/
.invoice-body {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.invoice-brand-section {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #f2f2f2;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.invoice-brand {
  font-size: 36px;
  color: var(--primary-color);
}

.invoice-number {
  font-size: 20px;
  color: #6c757d;
}

.invoice-logo {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.invoice-details {
  margin-bottom: 20px;
}

.customer-details,
.contact-details {
  font-size: 16px;
}

.customer-details h5 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #6c757d;
}

.payment-details-table {
  margin-bottom: 20px;
}

.payment-details-table .table {
  width: 100%;
  border-collapse: collapse;
}

.payment-details-table .table th,
.payment-details-table .table td {
  padding: 10px;
  text-align: left;
}

.payment-details-table .table th {
  background-color: var(--primary-bg-color);
  font-weight: bold;
}

.payment-details-table .table td {
  font-size: 16px;
}

.payment-details-table .table td.text-uppercase {
  text-transform: uppercase;
}

.thank-you-note {
  font-size: 14px;
  color: #6c757d;
  font-weight: 600;
}

@media (max-width: 768px) {
  .invoice-brand {
    font-size: 24px;
  }
  .invoice-logo {
    width: 75px;
    height: 75px;
  }
}
@media (max-width: 576px) {
  .invoice-body {
    padding-bottom: 2.5rem;
  }
  .invoice-brand {
    font-size: 18px;
  }
  .invoice-number {
    font-size: 14px;
  }
  .invoice-logo {
    width: 60px;
    height: 60px;
  }
  .customer-details h5 {
    font-size: 16px !important;
  }
}

/* ********QR LOADER******** */
/* From Uiverse.io by alexruix */
.qr-loader {
  width: 80px;
  height: 80px;
  margin-bottom: 40px;
}

.box1,
.box2,
.box3 {
  border: 16px solid #8d1400;
  box-sizing: border-box;
  position: absolute;
  display: block;
}

.box1 {
  width: 112px;
  height: 48px;
  margin-top: 64px;
  margin-left: 0px;
  animation: abox1 4s 1s forwards ease-in-out infinite;
}

.box2 {
  width: 48px;
  height: 48px;
  margin-top: 0px;
  margin-left: 0px;
  animation: abox2 4s 1s forwards ease-in-out infinite;
}

.box3 {
  width: 48px;
  height: 48px;
  margin-top: 0px;
  margin-left: 64px;
  animation: abox3 4s 1s forwards ease-in-out infinite;
}

@keyframes abox1 {
  0% {
    width: 112px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }

  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }

  25% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }

  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }

  50% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }

  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }

  75% {
    width: 48px;
    height: 112px;
    margin-top: 0px;
    margin-left: 0px;
  }

  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }

  100% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }
}

@keyframes abox2 {
  0% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }

  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }

  25% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }

  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }

  50% {
    width: 112px;
    height: 48px;
    margin-top: 0px;
    margin-left: 0px;
  }

  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }

  75% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }

  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }

  100% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }
}

@keyframes abox3 {
  0% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }

  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 0px;
    margin-left: 64px;
  }

  25% {
    width: 48px;
    height: 112px;
    margin-top: 0px;
    margin-left: 64px;
  }

  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }

  50% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }

  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }

  75% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }

  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }

  100% {
    width: 112px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0px;
  }
}

/* Default font sizes for larger screens */
.h1-responsive {
  font-size: 2.5rem;
}

.h2-responsive {
  font-size: 2rem;
}

.h3-responsive {
  font-size: 1.75rem;
}

.h4-responsive {
  font-size: 1.5rem;
}

.h5-responsive {
  font-size: 1.25rem;
}

.h6-responsive {
  font-size: 1rem;
}

.p-responsive {
  font-size: 1rem;
}
.p-responsive-sm {
  font-size: 1rem;
}

@media (max-width: 576px) {
  .h1-responsive {
    font-size: 2.2rem !important;
  }

  .h2-responsive {
    font-size: 1.8rem !important;
  }

  .h3-responsive {
    font-size: 1.6rem !important;
  }

  .h4-responsive {
    font-size: 1.4rem !important;
  }

  .h5-responsive {
    font-size: 1.1rem !important;
  }

  .h6-responsive {
    font-size: 0.9rem !important;
  }

  .p-responsive {
    font-size: 0.85rem !important;
  }
  .p-responsive-sm {
    font-size: 0.8rem !important;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

.footer-text-animation {
  animation: slideIn 20s linear infinite;
}
